(() => {
    document.addEventListener('DOMContentLoaded', () => {
        const items = [{
            "title": "Justification",
            "href": "./files/Justification.pdf"
        },
        {
            "title": "God's Intent",
            "href": "./files/Intent.pdf"
        },
        {
            "title": "John 1-12",
            "href": "./files/John%201-12.pdf"
        },
        {
            "title": "Election",
            "href": "./files/Election.pdf"
        },
        {
            "title": "The Path",
            "href": "./files/Path.pdf"
        },
        {
            "title": "Warnings",
            "href": "./files/Warnings.pdf"
        },
        {
            "title": "Hebrews",
            "href": "./files/Hebrews.pdf"
        },
        {
            "title": "The Promise",
            "href": "./files/The%20Promise.pdf"
        },
        {
            "title": "The Trinity",
            "href": "./files/The%20Trinity.pdf"
        },
        {
            "title": "His Return",
            "href": "./files/Return.pdf"
        },
        {
            "title": "Faith",
            "href": "./files/Faith.pdf"
        },
        {
            "title": "Romans",
            "href": "./files/Romans.pdf"
        },
        {
            "title": "Ephesians",
            "href": "./files/Ephesians.pdf"
        },
        {
            "title": "Cosmology",
            "href": "./files/Cosmology.pdf"
        },
        {
            "title": "Morality",
            "href": "./files/Morality.pdf"
        },
        {
            "title": "Paul's Hope",
            "href": "./files/Paul's_Hope.pdf"
        }
    ];

        const container = document.getElementById('card-items-container')
        items.forEach(item => {
            const cardItem = document.querySelector('.card-container').cloneNode(true);

            cardItem.querySelector('.card-title').innerHTML = item.title;
            cardItem.querySelector('.card-download').href = item.href;

            container.append(cardItem);
        })
    })
})();

